import { useRouter } from "next/router";
import { linkUtil } from "utils/i18n/link-util";

const isCurrentPage = (url: string): boolean => {
  const router = useRouter() || { asPath: "/" };

  return router.asPath == linkUtil.i18nLink(url);
};

const isParentOfCurrentPage = (parentUrl: string): boolean => {
  const router = useRouter() || { asPath: "/" };
  const currentPath = router.asPath;
  const parentPath = linkUtil.i18nLink(parentUrl);

  return currentPath.startsWith(parentPath);
};

export const navigationUtil = {
  isCurrentPage,
  isParentOfCurrentPage,
};
